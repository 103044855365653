<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedYamlActions"
        :default-value="properties.action"
        step-label="yaml"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-source`"
        v-model="properties.source"
        outlined
        dense
        :label="$lang.labels.source"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="yaml-source"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="yaml-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import StepMixin from './StepMixin'

import { definitions } from '@/utils/definitions'

export default {
  name: 'YAMLStep',
  components: { ActionSelect },
  mixins: [StepMixin],
  data() {
    return {
      yamlActions: definitions.YamlStepProperties.properties.action.enum
    }
  },
  computed: {
    formattedYamlActions() {
      return this.yamlActions.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'YAML_TO_JSON'
  }
}
</script>
